import React from "react";
import "../../../style.css";

const text2 = (
  <article className="community-body-content2">
    <div className="community-body-content-inner2">
      <p>
        <strong>Find a job or a worker</strong>
      </p>
      <p className="text-medium">
        Covering the need of the bussiness side ,provide support for it members.
        The chat groups can be a place where to look for jobs, find people to
        join your team or just get to know each other.
      </p>
    </div>
  </article>
);
export default text2;
