export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const SETUPGRIDADMIN = "/setupgridadmin";
export const SETUPGRIDGUEST = "/setupgridguest";
export const LEARN = "/LearnMore";
export const COMMUNITY = "/Community";
export const CONNECTION = "/Connection";
export const MARKET = "/Market";
export const INDUSTRY = "/Industry";
export const SOFTWARE = "/Software";
export const HARDWARE = "/Hardware";
export const PRIVACY = "/Privacy";
export const DISCLAIMER = "/Disclaimer";
export const SITEMAP = "/sitemap.xml";
