import React from "react";
import "../../../style.css";

const text4 = (
  <article className="learn-body-content4">
    <div className="learn-body-content-inner4">
      <strong className="text-large">
        DO YOU WANT TO CREATE YOUR OWN OPTION?
      </strong>
      <br></br>
      <strong className="text-medium"> Our direction ?</strong>
      <p className="text-small">
        Lot of friends, various untis installed in different countries and over
        50 international <br></br>installation partners, Nurumia contributes to
        the diversification of the procedence of our needed food and helps to
        share the benefits among its members.
        <br></br> in allcountries. Want to join us? Please contact us and let's
        start!
      </p>
    </div>
  </article>
);
export default text4;
