const stateSetup = {
  json: [],
  temp: "23",
  humi: "40",
  wTemp: "22",
  wLevel: "30",
  wPh: "6",
  pump: "0",
  fan: "1",
  lamp: "0",
  wHeater: "0",
  aHeater: "0",
  valveIn: "0",
  valveOut: "0",
  color1: "black",
  color2: "green",
  color3: "green",
  color4: "black",
  color5: "black",
  color6: "black",
  color7: "black",
  text1: "",
  text2: "",
  text3: "",
  text4: "",
  text5: "",
  text6: "",
  text7: "",
  textaHeater: "Air Heater ",
  textwHeater: "Water Heater ",
  textPump: "Water Pump",
  textValveIn: "Valve In",
  textValveOut: "Valve Out",
  textLamp: "Lamp",
  textFan: "Fan",
  black: "black",
  green: "green",
  new: "false"
  // on: "ON",
  //  off: "OFF"
};
export default stateSetup;
