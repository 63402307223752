const jsonToSendSetup = {
  //df,dv,ov,of,wi,wa,pi,pa,ai,aa,hi,ha
  df: "",
  dv: "",
  ov: "",
  of: "",
  wi: "",
  wa: "",
  pi: "",
  pa: "",
  ai: "",
  aa: "",
  hi: "",
  ha: ""
};

export default jsonToSendSetup;
